.containerMainMenu {
  position: relative;
  background-color: #2f3542;
  height: 100vh;
  width: 100%;
  background-size: cover;
}

.wrappMainMenu {
  position: absolute;
  top: 25%;
  margin-left: 20px;
  margin-right: 20px;
}

.animatedText {
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  padding-top: 20px;
}

.animatedText span {
  position: relative;
}

.animatedText span::before {
  content: "Selamat Datang Di Pendaftaran Pasien Rawat Jalan";
  color: #ff7f50;
}

.animatedText span::after {
  content: "";
  position: absolute;
  width: calc(100% + 8px);
  height: 100%;
  background-color: #2f3542;
  border-left: 2px solid #ff7f50;
  right: -8px;
  animation: cursor .8s infinite, typing 40s steps(14) infinite;
}

@keyframes cursor {
  to {
    border-left: 2px solid #ff7f5000;
  }
}

@keyframes typing {

  10%,
  15%,
  30%,
  35%,
  50%,
  55%,
  70%,
  75%,
  90%,
  95% {
    width: 0px;
  }

  5%,
  20%,
  25%,
  40%,
  45%,
  60%,
  65%,
  80%,
  85% {
    width: calc(100% + 8px);
  }
}